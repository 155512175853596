<template>
  <b-row>
    <b-col cols="12">
      <b-card title="All Registrants">
        <loading
          :active="isLoading"
          loader="bars"
          color="#7367f0"
          :is-full-page="fullPage"
        />
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          style-class="vgt-table striped"
          :total-rows="totalRecords"
          :is-loading.sync="isLoadingTable"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            skipDiacritics: true,
          }"
          :pagination-options="{
            enabled: true,
            perPageDropdownEnabled: false,
            dropdownAllowAll: false,
          }"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field == 'name'"
              class="text-nowrap"
            >
              <b-avatar
                :src="'https://servo.blob.core.windows.net/photos/' + props.row.photo"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.name }}</span>
            </span>
            <span v-else-if="props.column.field == 'certificate'">
              <b-img
                v-b-modal.modal-certificate
                :src="`https://servo.blob.core.windows.net/photos/${props.row.certificate}`"
                thumbnail
                fluid
                style="width: 200px;"
              />
              <!-- modal vertical center -->
              <b-modal
                id="modal-certificate"
                ref="modal-certificate"
                centered
                title="Approval"
                size="lg"
                ok-title="Accept"
                cancel-title="Deny"
              >
                <b-card-text>
                  <b-row class="mb-2">
                    <b-col cols="12">
                      <b-img
                        :src="`https://servo.blob.core.windows.net/photos/${props.row.certificate}`"
                        fluid-grow
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Note"
                      >
                        <b-form-textarea
                          v-model="baptism_verification_note"
                          rows="3"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-text>
                <template #modal-footer>
                  <button
                    v-b-modal.modal-close_visit
                    class="btn btn-success"
                    @click="setApproval(props.row.id, true)"
                  >
                    Accept
                  </button>
                  <button
                    v-b-modal.modal-close_visit
                    class="btn btn-secondary"
                    @click="setApproval(props.row.id, false)"
                  >
                    Deny
                  </button>
                </template>
              </b-modal>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar, BFormGroup, BFormInput, BCard, BCardText, BRow, BCol, BModal, VBModal, BImg, BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    BAvatar,
    BFormGroup,
    BFormInput,
    BCard,
    BCardText,
    VueGoodTable,
    // BBadge,
    BRow,
    BCol,
    Loading,
    BModal,
    BImg,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isLoading: true,
      isLoadingTable: false,
      baptism_verification_note: '',
      fullPage: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Church',
          field: 'church_name',
        },
        {
          label: 'Age',
          field: 'age',
        },
        {
          label: 'Gender',
          field: 'gender',
        },
        {
          label: 'Certificate',
          field: 'certificate',
        },
        {
          label: 'Baptism Date',
          field: 'baptism_date',
        },
        {
          label: 'Baptism Place',
          field: 'baptism_place',
        },
        {
          label: 'Baptism Type',
          field: 'baptism_type',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
          searchTerm: '',
        },
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 0,
        perPage: 10,
      },
      searchTerm: '',
      isRendered: false,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign(this.serverParams, Object.assign(newProps))
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPerPage * (params.currentPage - 1) })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },
    onSearch: _.debounce(function (params) {
      this.isLoading = true
      this.updateParams({ columnFilters: params })
      this.loadItems()
      return false
    }, 350),
    loadItems() {
      // Set data
      const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
      const churches = userData[0].churches.map(elem => elem).join(',')

      // Get the data
      this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registrations/baptism/${churches}?page=${this.serverParams.page}&perpage=${this.serverParams.perPage}&search=${this.serverParams.columnFilters.searchTerm}`)
        .then(response => {
          this.isLoading = false
          this.totalRecords = response.data.totalRecords
          this.rows = response.data.rows
      })
    },
    setApproval(id, approved) {
      this.$refs['modal-certificate'].hide()

      // Show loading
      this.isLoading = true

      // Set result
      // If failed then delete and refill again
      let data = {
        baptismVerificationNote: this.baptism_verification_note,
        servolutionRegistrationStatusId: 13,
        updatedAt: new Date(),
        deletedAt: new Date(),
      }
      if (approved) {
        data = {
          baptismVerificationNote: this.baptism_verification_note,
          servolutionRegistrationStatusId: 14,
          updatedAt: new Date(),
        }
      }

      // Update
      this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/servolution/registration/baptism/${id}`, data).then(() => {
        // hide loading and redirect
        this.isLoading = false

        // Success
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Data has been saved successfully.',
          },
        })

        const baptismListIndex = this.rows.findIndex(registration => registration.id === id)
        this.rows.splice(baptismListIndex, 1)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '../../../../node_modules/vue-image-lightbox/dist/vue-image-lightbox.min.css';

table.vgt-table {
  border: none;
  font-size: 1rem;
}
.vgt-table thead th {
  // border-bottom: 2px solid #ebe9f1;
  outline: none;
  background-color: #f3f2f7;
}
table.vgt-table td {
  vertical-align: middle !important;
}
.vgt-wrap__footer {
  border: none;
}
</style>